html {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
}

.text-center {
  text-align: center;
}

.main-window {
  margin-top: 1rem;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.max-width {
  max-width: 450px;
}

@media (max-width: 540px) {

  .main-window {
    margin-top: 1rem;
    padding: 10px;
  }

  .center {
    margin: auto;
    width: auto;
    padding: 0;
  }

  .max-width {
    max-width: 100%;
  }
}

#myTime {
  font-size: 2rem;
}


footer {
  color: #164198;
  padding-top: 3rem !important;
}

footer a {
  color: #556bc9;
}

.copyright-extern, .copyright-extern a {
  text-decoration: none;
  color: #8999fd;
}

.btn {
  background-color: #556bc9;
  color: white;
  text-decoration: none;
  margin: auto;
  width: 50%;
  padding: 0.8rem;
  font-size: 1.5rem;
}

.btn:hover {
  background-color: white;
  color: #556bc9;
  border: 1px solid #556bc9;
}

.header-image {
  width: auto;
  height: auto;
}

.description {
  padding-top: 0;
  color: grey;
}

.description a {
  color: gray;
}

.fine-arrow {
  display: block;
  max-width: 200px;
  padding: 0 0 0 5rem;
  margin-top: 3rem;
}
